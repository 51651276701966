// ** React Imports
import { useState, createContext } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import messagesEn from '@assets/data/locales/en.json'
import messagesDe from '@assets/data/locales/de.json'
import messagesFr from '@assets/data/locales/fr.json'
import messagesPt from '@assets/data/locales/pt.json'

// ** User Language Data
import userMessagesEn from '@src/assets/data/locales/en.json'
import userMessagesDe from '@src/assets/data/locales/de.json'
import userMessagesFr from '@src/assets/data/locales/fr.json'
import userMessagesPt from '@src/assets/data/locales/pt.json'

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, ...userMessagesEn },
  de: { ...messagesDe, ...userMessagesDe },
  fr: { ...messagesFr, ...userMessagesFr },
  pt: { ...messagesPt, ...userMessagesPt }
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const [locale, setLocale] = useState('en')
  const [messages, setMessages] = useState(menuMessages['en'])


  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
  }
  // const yesterday = {
  //   startDate: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate() - 1}`,
  //   endDate: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate() - 1}`
  // }


  function formatDate(date) {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    return `${year}-${month}-${day}`
  }
  // Today
  const Tday = new Date()
  const todayFormatted = formatDate(Tday)
  const today = { startDate: todayFormatted, endDate: todayFormatted }

  //yesterday***********************************************************
  const yestDay = new Date(Tday)
  yestDay.setDate(Tday.getDate() - 1)
  const yesterdayFormatted = formatDate(yestDay)
  const yesterday = { startDate: yesterdayFormatted, endDate: yesterdayFormatted }

  //Last Week*************************************************************
  const lastWeekStartDate = new Date(Tday)
  lastWeekStartDate.setDate(Tday.getDate() - 6)
  const lastWeekEndDate = new Date(Tday)
  lastWeekEndDate.setDate(Tday.getDate())
  const lastWeekStartFormatted = formatDate(lastWeekStartDate)
  const lastWeekEndFormatted = formatDate(lastWeekEndDate)
  const week = { startDate: lastWeekStartFormatted, endDate: lastWeekEndFormatted }


  // Current Month***************************************************
  const currentMonthStartDate = new Date(Tday.getFullYear(), Tday.getMonth(), 1)
  const currentMonthEndDate = new Date(Tday.getFullYear(), Tday.getMonth(), Tday.getDate())
  const currentMonthStartFormatted = formatDate(currentMonthStartDate)
  const currentMonthEndFormatted = formatDate(currentMonthEndDate)
  const currentMonth = { startDate: currentMonthStartFormatted, endDate: currentMonthEndFormatted }


  // Last Month***********************************************************************
  const lastMonthStartDate = new Date(Tday.getFullYear(), Tday.getMonth() - 1, 1)
  const lastMonthEndDate = new Date(Tday.getFullYear(), Tday.getMonth(), 0)
  const lastMonthStartFormatted = formatDate(lastMonthStartDate)
  const lastMonthEndFormatted = formatDate(lastMonthEndDate)
  const lastMonth = { startDate: lastMonthStartFormatted, endDate: lastMonthEndFormatted }

  const options = ['Today', 'Yesterday', 'Last Week', 'Current Month', 'Last Month']

  // Targetting States
  const stateSet1 = [
    { Abbreviation: "AN", RegionName: "Andaman and Nicobar Islands" },
    { Abbreviation: "AP", RegionName: "Andhra Pradesh" },
    { Abbreviation: "AR", RegionName: "Arunachal Pradesh" },
    { Abbreviation: "AS", RegionName: "Assam" },
    { Abbreviation: "BR", RegionName: "Bihar" },
    { Abbreviation: "CH", RegionName: "Chandigarh" },
    { Abbreviation: "CT", RegionName: "Chhattisgarh" },
    { Abbreviation: "DN", RegionName: "Dadra & Nagar Haveli & Daman and Diu" },
    { Abbreviation: "DL", RegionName: "Delhi" }
  ]
  const stateSet2 = [
    { Abbreviation: "GA", RegionName: "Goa" },
    { Abbreviation: "GJ", RegionName: "Gujarat" },
    { Abbreviation: "HR", RegionName: "Haryana" },
    { Abbreviation: "HP", RegionName: "Himachal Pradesh" },
    { Abbreviation: "JK", RegionName: "Jammu and Kashmir" },
    { Abbreviation: "JH", RegionName: "Jharkhand" },
    { Abbreviation: "KA", RegionName: "Karnataka" },
    { Abbreviation: "KL", RegionName: "Kerala" },
    { Abbreviation: "LA", RegionName: "Ladakh" }
  ]

  const stateSet3 = [
    { Abbreviation: "LD", RegionName: "Lakshadweep" },
    { Abbreviation: "MP", RegionName: "Madhya Pradesh" },
    { Abbreviation: "MH", RegionName: "Maharashtra" },
    { Abbreviation: "MN", RegionName: "Manipur" },
    { Abbreviation: "ML", RegionName: "Meghalaya" },
    { Abbreviation: "MZ", RegionName: "Mizoram" },
    { Abbreviation: "NL", RegionName: "Nagaland" },
    { Abbreviation: "OR", RegionName: "Odisha" },
    { Abbreviation: "PY", RegionName: "Puducherry" }
  ]

  const stateSet4 = [
    { Abbreviation: "PB", RegionName: "Punjab" },
    { Abbreviation: "RJ", RegionName: "Rajasthan" },
    { Abbreviation: "SK", RegionName: "Sikkim" },
    { Abbreviation: "TN", RegionName: "Tamil Nadu" },
    { Abbreviation: "TG", RegionName: "Telangana" },
    { Abbreviation: "TR", RegionName: "Tripura" },
    { Abbreviation: "UP", RegionName: "Uttar Pradesh" },
    { Abbreviation: "UK", RegionName: "Uttarakhand" },
    { Abbreviation: "WB", RegionName: "West Bengal" }
  ]

  function checkAll(setState, locStates, locPan) {
    const states = []
    const abbreviationData =
      ['AP', 'AR', 'AS', 'BR', 'CT', 'GA', 'GJ', 'HR', 'HP', 'JK', 'JH', 'KA', 'KL', 'LA', 'MP', 'MH', 'MN', 'ML', 'MZ', 'NL', 'OR', 'PY', 'PB', 'RJ', 'SK', 'TN', 'TG', 'TR', 'TG', 'UP', 'UK', 'WB', 'AN', 'CH', 'DN', 'DD', 'DL', 'LD', 'PY']
    abbreviationData.map(abre => states.push(abre))
    setState(states)
    localStorage.setItem(locStates, states)
    localStorage.setItem(locPan, 'IN')
  }

  return (
    <Context.Provider value={{ locale, switchLanguage, yesterday, today, week, currentMonth, lastMonth, options, stateSet1, stateSet2, stateSet3, stateSet4, checkAll }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='en'>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
