import { useState, createContext } from 'react'
const AlertContext = createContext()


const AlertWrapper = ({ children }) => {

    //global alerts
    const [alert, setAlert] = useState({ type: "", message: "" })
    function updateAlert(type, message) {
        setAlert({ type, message })
        // setTimeout(() => {
        //     setAlert({ type: "", message: "" })
        // }, 3000)
    }
    return (
        <AlertContext.Provider value={{ alert, updateAlert }}>
            {children}
        </AlertContext.Provider>
    )
}

export { AlertWrapper, AlertContext }
